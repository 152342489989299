<template>
  <div>
    <WaBtn />
    <!--header-->
    <MainHeader />

    <!-- main banner -->
    <MainBanner />

    <!-- about us section -->
    <div class="py-10 bg-red-50">
      <div class="container">
        <SubtitleHeading heading="About Us" class="text-red-900" />
        <div class="py-3 md:py-5">
          <div class="lg:flex lg:items-center">
            <div class="lg:w-1/2">
              <img src="/images/about-us.png" alt="About Us" class="mx-auto md:w-1/2 lg:w-3/4" />
            </div>
            <div class="pt-5 lg:w-1/2">
              <p class="text-base text-center text-gray-800 lg:text-left">
                Our company is equipped with a team of expert technicians who
                has more than
                <b>24 years of experience in washing machine and dryer repair.</b>
                We provide electrical appliance repairing services with an
                affordable price and we got you all covered in Klang Valley
                areas. In order to serve you better, we provide Door-to-Door
                Services as well. We promise to provide the best solutions with
                your inquiry!
                <b>Just give us a call @
                  <a href="tel:+60104228783" class="hover:text-red-900" id="call-btn-about">010-422 8783!</a>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- We Know You Are Facing -->
    <div class="py-10">
      <div class="container">
        <SubtitleHeading heading="We Know What You Are Facing Right Now " class="text-red-900" />
        <div class="pt-6">
          <div v-for="(item, i) in problem" :key="i" class="w-2/3 pb-2 mx-auto">
            <div class="px-1 py-3 bg-red-500">
              <p class="text-xl text-center text-white">{{ item.p }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Services -->
    <div class="py-10 bg-gray-100">
      <div class="container">
        <SubtitleHeading heading="Our Services" class="text-red-900" />
        <p class="pt-3 text-base text-center text-gray-800">
          We Provide Professional Door-to-Door Washing Machine & Dryer Repair and Chemical Cleaning
        </p>
        <div class="pt-6 md:pt-10 md:flex md:flex-wrap md:justify-center">
          <div v-for="(item, i) in service" :key="i" class="md:w-1/3">
            <img :src="item.image" :alt="item.alt" class="w-2/3 mx-auto md:w-full" />
          </div>
        </div>
        <p class="pt-3 text-sm text-center text-gray-800">
          Repair Your Damaged Machine Not Buying A New Machine It Helps You Save
          More Money
        </p>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/choose.jpg')">
      <div class="container">
        <SubtitleHeading heading="Why Choose Us" class="text-white" />
        <div class="flex flex-wrap justify-center pt-10 lg:w-3/4 lg:mx-auto">
          <div class="w-1/2 py-4 md:w-1/3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
              class="mx-auto text-white fill-current w-28 h-28">
              <path
                d="M50.014 29.854a1.55 1.55 0 00-1.55 1.55v10.848h4.649a5.085 5.085 0 011.551.264V31.403a1.55 1.55 0 00-1.551-1.55h-3.099zM68.61 29.854h3.1c.855 0 1.551.694 1.551 1.55V46.9a1.55 1.55 0 01-1.551 1.55h-3.1a1.55 1.55 0 01-1.549-1.55V31.403a1.55 1.55 0 011.549-1.549zM57.732 27.048a17.164 17.164 0 00-7.718-1.844 16.884 16.884 0 00-7.083 1.55h.883a4.62 4.62 0 013.1 1.208 4.613 4.613 0 013.1-1.208h3.099a4.62 4.62 0 013.1 1.208 4.854 4.854 0 011.519-.914z" />
              <path
                d="M71.71 51.549h-3.1a4.608 4.608 0 01-3.099-1.208 4.608 4.608 0 01-3.1 1.208h-3.115a4.55 4.55 0 01-2.324-.649 10.815 10.815 0 01-10.058 6.849h-.294a13.004 13.004 0 014.897 7.377 17.82 17.82 0 010 8.478l-3.006-.714a14.924 14.924 0 000-7.019c-1.039-4.186-3.813-5.874-7.021-7.827l-.481-.295h-4.943V54.65h10.848a7.752 7.752 0 007.75-7.75 1.494 1.494 0 00-1.551-1.55H31.526a9.362 9.362 0 00-7.857 9.098l3.053 12.227c1.41 4.944 5.594 9.392 7.331 11.081.296.296.463.696.464 1.115v9.873a4.661 4.661 0 004.649 4.648h21.697a4.663 4.663 0 004.649-4.648v-9.423a1.517 1.517 0 01.527-1.163 26.454 26.454 0 008.693-12.816L77.32 49.83a24.32 24.32 0 00-1.131-1.752 4.635 4.635 0 01-4.479 3.471zM54.664 90.293v-3.1a4.65 4.65 0 004.648-4.648h3.1a7.757 7.757 0 01-7.748 7.748z" />
              <path
                d="M59.313 29.854h3.1a1.55 1.55 0 011.549 1.55V46.9a1.55 1.55 0 01-1.549 1.55h-3.1a1.552 1.552 0 01-1.551-1.55V31.403a1.55 1.55 0 011.551-1.549zM36.066 32.458c-.125.17-.232.325-.356.496h.356v-.496zM40.715 29.854a1.55 1.55 0 00-1.55 1.55v10.848h6.199V31.403a1.55 1.55 0 00-1.55-1.55h-3.099zM25.001 45.072c.385-.587.711-1.21.977-1.859a1.512 1.512 0 011.426-.961h8.663v-6.198h-8.663a1.551 1.551 0 01-1.441-.977 10.783 10.783 0 00-10.043-6.772 10.894 10.894 0 00-7.593 3.1h6.044a1.55 1.55 0 011.549 1.55v12.397a1.55 1.55 0 01-1.549 1.55H8.326a10.9 10.9 0 007.593 3.1 10.79 10.79 0 006.85-2.464 12.49 12.49 0 012.232-2.466zM84.107 45.351V32.953c0-.856.695-1.55 1.551-1.55h6.043a10.813 10.813 0 00-15.342.186V43.8a15.314 15.314 0 013.937 4.991c.094.178.152.373.17.573 1.168.423 2.4.638 3.642.636a10.897 10.897 0 007.594-3.1h-6.043a1.55 1.55 0 01-1.552-1.549z" />
              <path
                d="M28.411 32.953h3.735a20.12 20.12 0 0130.777-6.152c.963.11 1.867.517 2.589 1.162a4.61 4.61 0 013.099-1.208h3.1a4.614 4.614 0 013.534 1.658 13.777 13.777 0 016.245-2.944l-2.248-3.875-7.949 3.472a3.138 3.138 0 01-3.439-.65 3.101 3.101 0 01-.652-3.426l3.457-7.981-5.658-3.255-5.19 7.005a3.1 3.1 0 01-5.564-1.503l-.961-8.647h-6.709l-.977 8.585a3.068 3.068 0 01-5.16 1.921c-.047-.031-.093-.077-.14-.108a3.269 3.269 0 01-.31-.403l-5.098-6.865-5.626 3.254 3.441 7.873a3.034 3.034 0 01-.651 3.425 3.064 3.064 0 01-3.425.666l-7.904-3.44-2.263 3.952a13.822 13.822 0 019.947 7.484z" />
            </svg>
            <div class="pt-4">
              <h1 class="text-base font-semibold leading-tight text-center text-white md:text-lg">
                24 Years' <br />
                Experience
              </h1>
            </div>
          </div>
          <div class="w-1/2 py-4 md:w-1/3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
              class="mx-auto text-white fill-current w-28 h-28">
              <path
                d="M50.59 10.166c-15.481 0-28.073 12.592-28.073 28.073S35.109 66.312 50.59 66.312c15.476 0 28.073-12.592 28.073-28.073S66.065 10.166 50.59 10.166zm14.757 24.102l-4.849 6.074.529 7.937a2.537 2.537 0 01-1.071 2.251c-.443.312-.96.47-1.48.47-.337 0-.679-.066-.995-.204l-6.891-2.925-6.885 2.925a2.543 2.543 0 01-2.476-.266 2.563 2.563 0 01-1.072-2.251l.531-7.937-4.849-6.074a2.554 2.554 0 011.348-4.063l7.237-1.894 3.972-6.687c.919-1.552 3.47-1.552 4.384 0l3.971 6.687 7.244 1.894a2.559 2.559 0 011.352 4.063z" />
              <path
                d="M21.879 54.791l-11.78 20.453c-.515.898-.438 2.017.189 2.833s1.685 1.185 2.685.908l14.986-4.026 3.986 14.985a2.56 2.56 0 002.128 1.873c.113.011.23.016.337.016.903 0 1.75-.48 2.21-1.276l11.143-19.283c-11.072-.94-20.586-7.336-25.884-16.483zM91.087 75.244L79.301 54.791c-5.293 9.147-14.812 15.543-25.883 16.482L64.56 90.557a2.555 2.555 0 002.542 1.255 2.567 2.567 0 002.135-1.872l3.985-14.986 14.985 4.027a2.543 2.543 0 002.686-.908c.632-.812.704-1.93.194-2.829z" />
            </svg>
            <div class="pt-4">
              <h1 class="text-base font-semibold leading-tight text-center text-white md:text-lg">
                1 Year <br />
                Warranty
              </h1>
            </div>
          </div>
          <div class="w-1/2 py-4 md:w-1/3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
              class="mx-auto text-white fill-current w-28 h-28">
              <path
                d="M42.571 6.181v6.472H22v75.745l20.571.017v6.549l37.558-6.745.04-75.244-37.598-6.794zm6.23 41.42c1.262 0 2.283 1.332 2.283 2.973 0 1.642-1.021 2.972-2.283 2.972s-2.285-1.33-2.285-2.972 1.023-2.973 2.285-2.973zM28.107 82.297V18.761h14.464v63.547l-14.464-.011z" />
            </svg>
            <div class="pt-4">
              <h1 class="text-base font-semibold leading-tight text-center text-white md:text-lg">
                Immediate <br />
                Door to Door Service
              </h1>
            </div>
          </div>
          <div class="w-1/2 py-4 md:w-1/3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
              class="mx-auto text-white fill-current w-28 h-28">
              <path
                d="M56.471 48.971a6.866 6.866 0 013.139-.751 6.86 6.86 0 014.872 2.011l2.807-2.805c6.944 2.433 14.975.876 20.525-4.675 4.671-4.671 6.513-11.097 5.53-17.155a1.802 1.802 0 00-3.051-.986l-4.166 4.166a8.69 8.69 0 01-12.288-12.287l4.167-4.166a1.799 1.799 0 00-.986-3.05c-6.057-.983-12.485.858-17.155 5.529-5.633 5.634-7.15 13.821-4.559 20.834l-6.085 6.085 7.25 7.25zM45.38 59.998l-7.218-7.217-25.24 25.239a8.404 8.404 0 1011.885 11.887l21.758-21.758a6.926 6.926 0 01-1.185-8.151z" />
              <path
                d="M61.479 53.261c-.518-.518-1.194-.775-1.87-.775s-1.353.258-1.869.775l-1.508 1.506-26.839-26.84 1.255-1.255a2.645 2.645 0 00-.302-3.998l-10.951-8.065a2.644 2.644 0 00-3.437.26l-4.65 4.65a2.644 2.644 0 00-.259 3.437l8.064 10.951a2.644 2.644 0 003.998.301l1.254-1.255 26.841 26.84-1.612 1.613a2.642 2.642 0 000 3.738l24.761 24.762a8.404 8.404 0 0011.884-11.887l-24.76-24.758z" />
            </svg>
            <div class="pt-4">
              <h1 class="text-base font-semibold leading-tight text-center text-white md:text-lg">
                Expert in Washing Machine <br> and
                Dryer Repairing
              </h1>
            </div>
          </div>
          <div class="w-1/2 py-4 md:w-1/3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
              class="mx-auto text-white fill-current w-28 h-28">
              <path
                d="M43.043 56.532c0 4.035 3.284 7.318 7.319 7.318s7.32-3.283 7.32-7.318c0-4.036-3.284-7.319-7.32-7.319s-7.319 3.283-7.319 7.319zM50.363 68.729c-8.072 0-14.638 6.567-14.638 14.64v9.921h29.277v-9.921c0-8.072-6.567-14.64-14.639-14.64zM30.846 61.411c0-4.035-3.284-7.319-7.319-7.319s-7.319 3.284-7.319 7.319c0 4.036 3.284 7.318 7.319 7.318s7.319-3.282 7.319-7.318zM84.518 61.411c0-4.035-3.283-7.319-7.318-7.319s-7.318 3.284-7.318 7.319c0 4.036 3.283 7.318 7.318 7.318s7.318-3.282 7.318-7.318zM21.086 10.016l-3.711 8.75-8.649 1.22 6.239 5.925-1.435 8.665 7.557-3.95 7.557 3.95-1.434-8.665 6.075-5.925-8.396-1.22-3.803-8.75zM54.165 18.766l-3.802-8.75-3.711 8.75-8.487 1.22 6.076 5.925-1.434 8.665 7.557-3.95 7.557 3.95-1.435-8.665 6.075-5.925-8.396-1.22zM79.639 10.016l-3.711 8.75-8.488 1.22 6.077 5.925-1.435 8.665 7.557-3.95 7.559 3.95-1.436-8.665L92 19.986l-8.559-1.22-3.802-8.75zM77.199 73.61c-3.243 0-6.21 1.089-8.637 2.871.817 2.15 1.318 4.454 1.318 6.888v9.921H92v-5.042c0-8.085-6.717-14.638-14.801-14.638zM8.726 88.248v5.042h22.12v-9.921c0-2.434.5-4.737 1.316-6.888-2.426-1.782-5.393-2.871-8.636-2.871-8.085 0-14.8 6.553-14.8 14.638z" />
            </svg>
            <div class="pt-4">
              <h1 class="text-base font-semibold leading-tight text-center text-white md:text-lg">
                99% Customer <br />
                Satisfaction
              </h1>
            </div>
          </div>
        </div>
        <!-- cta-enquiry -->
        <a id="call-btn-choose" href="tel:+60104228783">
          <div
            class="w-1/2 py-1 mx-auto mt-5 text-base font-semibold text-center text-white border-2 border-white md:w-1/4 hover:text-gray-800 hover:bg-white lg:w-1/5">
            Call Us Now
          </div>
        </a>
      </div>
    </div>

    <!-- Our Brand Cover -->
    <div class="py-10">
      <div class="container">
        <SubtitleHeading heading="We Serve These Brands " class="text-red-900" />
        <div class="flex flex-wrap pt-6 md:justify-center">
          <div v-for="(item, i) in brand" :key="i" class="w-1/2 md:w-1/4 lg:w-1/5">
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!-- Our Testimonial -->
    <div class="py-10">
      <div class="container">
        <SubtitleHeading heading="Our Testimonial" class="text-red-900" />

        <!-- testimonial content -->
        <div class="py-10">
          <carousel :autoplay="true" :loop="true" :responsive="{
            0: { items: 1, nav: false },
            700: { items: 2, nav: false },
            1000: { items: 3, nav: false },
          }">
            <!--carousel area-->
            <div v-for="(item, i) in testimonial" :key="i" class="md:px-2">
              <div class="px-3 py-6 bg-red-400">
                <p class="flex items-center justify-center h-40 pb-4 text-base text-center text-white h-30">
                  {{ item.p }}
                </p>
                <div class="w-32 mx-auto">
                  <img src="/images/rating.png" alt="" class="">
                </div>
                <h1 class="pt-4 text-xl font-semibold text-center text-white">
                  {{ item.client }}
                </h1>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- OUR COVER AREA -->
    <!-- <div class="py-10 bg-red-900">
      <div class="container">
        <SubtitleHeading heading="Our Cover Area" class="text-white" />
        <div class="pt-6 md:flex">
          <ul class="px-3 text-sm text-white list-disc">
            <li>Bukit Jalil, Sri Petaling, Salak South, OKR, Tmn Desa, OUG</li>
            <li>Bangsar, Mid Valley, Jalan Pantai Dalam</li>
            <li>Puchong</li>
            <li>Setapak, Setia Wangsa, Jelatek, Batu Caves</li>
            <li>
              Cheras (Include Taman Midah, Bandar Sri Permaisuri, Taman
              Connough, Cheras Batu 10)
            </li>
            <li>
              Sentul, Jalan Ipoh, Jalan Kuching, Jinjang Utara, Kepong Baru
            </li>
            <li>
              Ampang, Jalan Ampang Hilir, Pandan Indah, Tmn Maluri, Pandan
              Perdana, Jalan Loke Yew, Pudu,
            </li>
            <li>Bandar Baru Ampang, Taman Cempaka, Taman Pandan Ria</li>
          </ul>
          <ul class="pl-3 text-sm text-white list-disc">
            <li>
              PJ (Include SS2, Ara damansara, Kelana Jaya, Bandar Utama, Kota
              Damansara)
            </li>
            <li>TTDI</li>
            <li>
              KL Town (Jalan Petaling, Jalan Sultan Ismail, Brickfield, Mont
              Kiara, Bukit Damansara)
            </li>
            <li>Kepong (Include Bandar Sri Damansara, Bandar Manjalara)</li>
            <li>Kajang (include Bandar Mahkota Cheras, Tmn Bkt Segar, etc.)</li>
            <li>Sri Kembangan, Bukit Serdang</li>
            <li>Putrajaya</li>
            <li>Kajang, Semenyih, Bangi</li>
            <li>Subang, Subang Jaya, Sunway, Sea Park</li>
            <li>Sungai Buloh</li>
          </ul>
        </div>
      </div>
    </div> -->

    <!-- enquiry form -->
    <!-- <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/enquiry.jpg')"
    >
      <div class="container">
        <SubtitleHeading heading="Send An Enquiry" class="pb-4 text-white" />
        <div class="md:w-2/3 md:mx-auto">
          <div class="py-8 bg-red-900 bg-opacity-60">
            
            <iframe
              allowTransparency="true"
              style="min-height: 520px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5736488.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            >
          </div>
        </div>
      </div>
    </div> -->

    <!-- Footer -->
    <MainFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import SubtitleHeading from "@/components/SubtitleHeading.vue";
import carousel from "vue-owl-carousel";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import MainFooter from "@/components/MainFooter.vue";
import Copyright from "@/components/Copyright.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  components: {
    SubtitleHeading,
    carousel,
    MainHeader,
    MainBanner,
    MainFooter,
    Copyright,
    WaBtn,
  },
  data() {
    return {
      problem: [
        { p: "Washing Machine Leaking" },
        { p: "Washing Machine & Dryer Have Loud Noise" },
        { p: "Washing Machine Malfunction" },
        { p: "Dryer Not Heating Well" },
      ],
      service: [
        { image: "/images/service-1.png", alt: "Washing Machine" },
        { image: "/images/service-2.png", alt: "Dryer Machine" },
        { image: "/images/service-3.png", alt: "Fridge" },
      ],
      brand: [
        // { image: "/images/brand-1.jpg", alt: "Hier" },
        { image: 'images/brand-13.jpg', alt: 'Bosch' },
        { image: "/images/brand-2.jpg", alt: "Hitachi" },
        { image: "/images/brand-3.jpg", alt: "LG" },
        // { image: "/images/brand-4.jpg", alt: "Mitsubishi" },
        { image: "/images/brand-5.jpg", alt: "Panasonic" },
        // { image:'images/brand-14.jpg', alt:'Hisense'},
        // { image: "/images/brand-6.jpg", alt: "Pensonic" },
        { image: "/images/brand-7.jpg", alt: "Samsung" },
        // { image: "/images/brand-8.jpg", alt: "Sharp" },
        { image: "/images/brand-9.jpg", alt: "Toshiba" },
        // { image: "/images/brand-10.jpg", alt: "York" },
        { image: 'images/brand-11.jpg', alt: 'Electrolux' },
        { image: 'images/brand-12.jpg', alt: 'Beko' },
        { image: 'images/brand-15.jpg', alt: 'Xiaomi' },
      ],
      testimonial: [
        {
          p: "Very nice and professional, we really appreciate the fix of a washing machine on short notice! Reasonable pricing as well, highly recommend.",
          client: "Mr Wong",
        },
        {
          p:'Very fast response and professional. Solved my washing machine spinning problem. The charges are worth for both problem. Recommended!!',
          client:'Ms Kee'
        },
        {
          p:'i already sent the machine for chemical wash, so fast just 2days',
          client:'Ms Lylia'
        },
        {
          p:'Fast response. Friendly services. Very professional and reliable. Reasonable pricing. Definitely the best experience for me so far.',
          client:'Mr Surean'
        },
        {
          p:'Fast service.. repair my washing machine within one day.. im satisfied.. very recommended..',
          client:'Mr Zahir'
        },
      ],
    };
  },
};
</script>

<style></style>
