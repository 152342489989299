<template>
  <!-- <div class="py-10 bg-black">
    <div class="container">
      <div class="lg:flex lg:flex-wrap">
        <div class="lg:w-1/2">
          <img
            src="/images/logo-white.png"
            alt="Pusat Penyaman Udara Wawasan HBC"
            class="mx-auto"
          />
          <div class="pt-4">
            <p class="text-base text-center text-white">
              No. 23, Jalan 8/125D, Desa Petaling, 57100 Kuala Lumpur
            </p>
            <a
              href="tel:+60173323093"
              class="block pt-3 text-lg font-semibold text-center text-white hover:text-gray-400"
            >
              Call Us : 017-332 3093
            </a>
          </div>
        </div>
        <div id="map" class="pt-4 lg:w-1/2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0461611464493!2d101.70212335055488!3d3.082354554407633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a782bd6ca4b%3A0x330ac0d595dc672!2s23%2C%20Jalan%208%2F125d%2C%20Desa%20Petaling%2C%2057100%20Kuala%20Lumpur%2C%20Wilayah%20Persekutuan%20Kuala%20Lumpur!5e0!3m2!1sen!2smy!4v1606464233221!5m2!1sen!2smy"
            width="100%"
            height="250"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div> -->
  <footer class="py-8 bg-gray-300">
    <div class="container">
      <div class="w-full lg:pt-8 lg:pr-4">
        <img
          src="/images/logo-color.png"
          alt="Washing Machine Repair KL"
          class="mx-auto lg:w-1/2"
        />
        <div class="text-center">
          <p class="text-xl font-semibold lg:text-2xl">
            <i class="fas fa-mobile-alt"></i> Mobile :
            <a href="tel:+60104228783" id="call-btn-bottom">010-4228783</a>
          </p>
          <p class="pt-1 text-xl font-semibold lg:text-2xl">
            <i class="fab fa-whatsapp-square"></i> Whatsapp :
            <a href="https://wa.me/60104228783" id="wa-btn-bottom"
              >010-4228783</a
            >
          </p>
        </div>
      </div>
      <div class="w-full py-5">
        <div class="py-4 text-center">
          <h1 class="text-xl font-bold">Our Coverage Area</h1>
          <div class="md:flex">
            <div class="py-2 md:w-1/2">
              <p class="font-bold">Kuala Lumpur</p>
              <div class="flex justify-center">
                <div class="w-1/2">
                  <ul>
                    <li>Bukit Jalil</li>
                    <li>Sri Petaling</li>
                    <li>Salak South</li>
                    <li>OKR</li>
                    <li>Taman Desa</li>
                    <li>OUG</li>
                    <li>Bangsar</li>
                    <li>Mid Valley</li>
                    <li>Jalan Pantai Dalam</li>
                    <li>Setapak</li>
                    <li>Setia Wangsa</li>
                    <li>Jelatek</li>
                    <li>Batu Caves</li>
                    <li>Taman Midah</li>
                    <li>Bandar Sri Permaisuri</li>
                    <li>Taman Connaught</li>
                    <li>Sentul</li>
                    <li>Jalan Ipoh</li>
                     <li>Jalan Kuching</li>
                  </ul>
                </div>
                <div class="w-1/2">
                  <ul>
                    <li>Jinjang Utara</li>
                    <li>Kepong Baru</li>
                    <li>Jalan Petaling</li>
                    <li>Jalan Sultan Ismail</li>
                    <li>Brickfield</li>
                    <li>Mont Kiara</li>
                    <li>Bukit Damansara</li>
                    <li>Bandar Sri Damansara</li>
                    <li>Bandar Manjalara</li>
                    <li>Bukit Segar</li>
                    <li>Jalan Ampang Hilir</li>
                    <li>Taman Maluri</li>
                    <li>Pandan Perdana</li>
                    <li>Jalan Loke Yew</li>
                    <li>Pudu</li>
                    <li>TTDI</li>
                    <li>Taman Keramat</li>
                    <li>Melawati</li>
                    <li>Putrajaya</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="py-2 md:w-1/2">
              <p class="font-bold">Selangor</p>
              <div class="flex justify-center">
                <div class="w-1/2">
                  <ul>
                    <li>Puchong</li>
                    <li>Ara Damansara</li>
                    <li>Kelana Jaya</li>
                    <li>Bandar Utama</li>
                    <li>Kota Damansara</li>
                    <li>Kajang</li>
                    <li>Bandar Mahkota Cheras</li>
                    <li>Sri Kembangan</li>
                    <li>Bandar Baru Ampang</li>
                    <li>Bukit Serdang</li>
                    <li>Sierramas West Sungai Buloh</li>
                    <li>Taman Alam Impian Shah Alam</li>
                    <!-- <li>Semenyih</li> -->
                  </ul>
                </div>
                <div class="w-1/2">
                  <ul>
                    <li>Bangi</li>
                    <li>Subang</li>
                    <li>Subang Jaya</li>
                    <li>Sunway</li>
                    <li>Sea Park</li>
                    <!-- <li>Sungai Buloh</li> -->
                    <li>Ukay Perdana</li>
                    <li>Cheras Batu 10</li>
                    <li>Pandan Indah</li>
                    <li>Taman Cempaka</li>
                    <li>Taman Pandan Ria Subang</li>
                    <li>Bukit Rahman Putra Sungai Buloh</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>