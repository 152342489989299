<template>
  <div class="py-3 bg-white">
    <div class="container mx-auto">
      <h2 class="text-xs font-medium text-center text-red-900 md:text-sm">
        Website maintained by Activa Media. All Rights Reserved.
      </h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>