<template>
  <div
    class="py-10 bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-20 md:py-32 md:w-3/4 md:mx-auto">
        <h1
          class="text-4xl font-bold text-center text-white uppercase md:text-5xl"
        >
          <span class="text-yellow-300">26 Years Trusted</span> Washing Machine And Dryer
        </h1>
        <h1 class="pt-2 text-lg font-medium text-center text-white md:text-xl">
          Door to Door Repair Service Company in KL
        </h1>
        <div class="pt-5">
          <p class="text-base text-center text-white">
            Call Us Now for Immediate, On-The-Spot Consultation on Your Washing Machine Or Dryer Repair.
          </p>
          <!-- cta-enquiry -->
          <a v-if="!hideEnquiry" id="call-btn-banner" href="tel:+60104228783">
            <div
              class="w-1/2 py-1 mx-auto mt-5 text-base font-semibold text-center text-white border-2 border-white md:w-1/4 hover:text-gray-800 hover:bg-white lg:w-1/5"
            >
              Call Us Now
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>