<template>
  <header class="bg-red-900">
    <div class="container">
      <div class="py-3 md:flex md:justify-between md:items-center">
        <div class="md:w-1/2">
          <img
            src="/images/logo-white.png"
            alt="Washing Machine Repair KL"
            class="w-full mx-auto md:mx-0 md:w-full lg:w-2/3"
          />
        </div>
        <!-- cta-button -->
        <div class="pt-3 md:pt-0">
          <h1 class="text-lg font-semibold text-center text-white md:text-left">
            Call Us :
          </h1>
          <a href="tel:+60104228783" id="call-btn-top">
            <div
              class="w-2/3 mx-auto bg-white rounded-md md:px-3 md:w-full md:mx-0"
            >
              <h1 class="py-2 text-xl font-semibold text-center text-red-900">
                <i class="pr-1 fas fa-phone-alt"></i>
                010-4228783
              </h1>
            </div>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>