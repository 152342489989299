<template>
  <div class="flex items-center justify-center">
    <h1 class="text-2xl font-semibold text-center" :class="custom">
      {{heading}}
    </h1>
  </div>
</template>

<script>
export default {
    props:['heading','custom']
};
</script>
